/* eslint-disable */

import React, { useMemo, useState, useEffect } from 'react'
import Table, { SelectColumnFilter } from './Table' // new
import axios from 'axios'
import LoadingOverlay from './LoadingOverlay.jsx'
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function ProxyTable() {
  const [loadingData, setLoadingData] = useState(true);
  // timeout maximum
  const [maxTimeout, setMaxTimeout] = useState(0.0)
  const columns = useMemo(() => [
    {
      Header: 'Proto',
      accessor: 'proto',
      Filter: SelectColumnFilter,
      filter: 'includes',
      Cell: ({ cell: { value } }) => {
        return (
          <p className="text-sm text-gray-500 dark:text-gray-500 font-bold">{value}</p>
        )
      },
    },
    {
      Header: 'IP',
      accessor: 'ip',
      Cell: ({ cell: { value } }) => {
        return (
          <div className="w-full inline-flex items-center cursor-pointer" onClick={() => {
            // copy to clipboard on click
            navigator.clipboard.writeText(value);
            
            // show toast notification
            toast.success('IP address copied to clipboard!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}>
            <p className="text-sm text-blue-600/100 dark:text-blue-500/100">{value}</p>
          </div>
        )
      },
    },
    {
      Header: 'Country Code',
      accessor: 'countryCode',
      Filter: SelectColumnFilter,
      filter: 'includes',
      Cell: ({ cell: { value, row: { original } } }) => {
        const geolocation = original.geolocation.replace(/\|/g, '<br />');

        return (
          <div className="w-full inline-flex items-center justify-center cursor-pointer" data-tooltip-id={`tooltip-${value}`} data-tooltip-html={geolocation}>
            <img className="mr-2" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} style={{ width: 20 }} />
            <p className="text-sm text-gray-500">{value}</p>
            <Tooltip id={`tooltip-${value}`} />
          </div>
        );
      },
    },
    {
      Header: 'Is Anonymous',
      accessor: 'isAnonymous',
      Filter: SelectColumnFilter,
      filter: 'includes',
      Cell: ({ cell: { value } }) => {
        return (
          <div className="w-full">
            { value == 'Yes' ? (
              <div className="inline-flex items-center">
                <img className="mr-2" src="https://icons.veryicon.com/png/o/business/bitcoin-icon/anonymous-4.png" style={{ width: 20 }} />
                <p className="text-sm text-gray-500">{value}</p>
              </div>
            ) : (
              <div className="inline-flex items-center">
                <p className="text-sm text-gray-500">{value}</p>
              </div>
            )}
          </div>
        )
      }
    },
    {
      Header: 'Timeout',
      accessor: 'timeout',
      Cell: ({ cell: { value } }) => {
        var percent_value = value / maxTimeout * 100
        percent_value = Math.floor(percent_value)
        let progress_bar = value
        if (percent_value <= 30) {
          progress_bar = <div className="bg-green-600 h-2.5 rounded-full dark:bg-green-500" style={{width: percent_value}}></div>
        }
        if (percent_value > 30 && progress_bar <= 60) {
          progress_bar = <div className="bg-yellow-400 h-2.5 rounded-full" style={{width: percent_value}}></div>
        }
        if (percent_value > 60) {
          progress_bar = <div className="bg-red-600 h-2.5 rounded-full dark:bg-red-500" style={{width: percent_value}}></div>
        }
        return (
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-200">
            {progress_bar}
            <p className="text-sm text-gray-500">{value.toFixed(3)}ms</p>
          </div>
        );
      },
    }
  ], [maxTimeout])

  const [data, setData] = useState([])

  useEffect(() => {
    async function getData() {
      await axios
        .get('https://proxy.nishimiya.su/api/', {
          'Access-Control-Allow-Origin': '*'
        })
        .then((response) => {
          // check if the data is populated
          // console.log(response.data)

          // max timeout variable
          var max_timeout = 0.0

          // reformat the json object
          var res_data = []
          // http
          for (const [key, value] of Object.entries(response.data.http)) {
            // set maximum timeout
            max_timeout = Math.max(max_timeout, value.timeout)
            let is_anon = value.is_anonymous ? 'Yes' : 'No'
            res_data.push({
              proto: 'HTTP',
              ip: value.ip,
              countryCode: value.country_code,
	      geolocation: value.geolocation,
              isAnonymous: is_anon,
              timeout: value.timeout
            })
          }
          // socks4
          for (const [key, value] of Object.entries(response.data.socks4)) {
            // set maximum timeout
            max_timeout = Math.max(max_timeout, value.timeout)
            let is_anon = value.is_anonymous ? 'Yes' : 'No'
            res_data.push({
              proto: 'SOCKS4',
              ip: value.ip,
              countryCode: value.country_code,
	      geolocation: value.geolocation,
              isAnonymous: is_anon,
              timeout: value.timeout
            })
          }
          // socks5
          for (const [key, value] of Object.entries(response.data.socks5)) {
            // set maximum timeout
            max_timeout = Math.max(max_timeout, value.timeout)
            let is_anon = value.is_anonymous ? 'Yes' : 'No'
            res_data.push({
              proto: 'SOCKS5',
              ip: value.ip,
              countryCode: value.country_code,
	      geolocation: value.geolocation,
              isAnonymous: is_anon,
              timeout: value.timeout
            })
          }

          // set max timeout
          setMaxTimeout(max_timeout)

          // sort res_data by timeout
          res_data.sort(function(a,b) {
            if (a.timeout < b.timeout) return -1
            if (a.timeout > b.timeout) return 1
            return 0
          })

          setData(res_data)
          // you tell it that you had the result
          setLoadingData(false)
        })
    }
    if (loadingData) {
      // if the  result is not ready so you make the axios cell
      getData();
    }
  }, [])

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        {/* <div className="">
          <h1 className="text-xl font-semibold">Proxy list</h1>
        </div> */}
        <div className="mt-6">
          {loadingData ? (
            <LoadingOverlay />
          ) : (
            <Table columns={columns} data={data} />
          )}
        </div>
      </main>
    </div>
  )
}



export default ProxyTable
