/* eslint-disable */

import React from 'react'
import ProxyTable from './components/ProxyTable.jsx'
import Navbar from './components/Navbar.jsx'
import Footer from './components/Footer.jsx'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <ProxyTable />
      <Footer/>

      <ToastContainer />
    </div>
  )
}



export default App